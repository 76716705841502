<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('admin.table.settings.empty_text')"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(type)="data">
          {{ $t(`messages.types.${data.item.type}`) }}
        </template>

        <template #cell(actions)="data">
          <b-button
            class="btn btn-primary btn-sm mr-1"
            @click="openedModalForm = true; dataModalForm = createMessageHtml(data.item, '')"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="align-middle"
            />
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('admin.table.settings.showing') }} {{ dataMeta.from }} {{ $t('admin.table.settings.to') }} {{ dataMeta.to }} {{ $t('admin.table.settings.of') }} {{ dataMeta.of }} {{ $t('admin.table.settings.entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <modal-form
      :opened-modal-form.sync="openedModalForm"
      :item="dataModalForm"
    />
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import useList from './useList'
import storeModule from './storeModule'
import ModalForm from './ModalForm.vue'

export default {
  components: {
    ModalForm,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      openedModalForm: false,
      dataModalForm: '',
      tableColumns: [
        { key: 'created_at', label: this.$t('admin.table.fields.created_at'), sortable: false },
        { key: 'type', label: this.$t('admin.table.fields.type'), sortable: false },
        { key: 'actions', label: this.$t('admin.table.fields.actions'), sortable: false },
      ],
    }
  },
  methods: {
    createMessageHtml(item, html = '', tag = 'div') {
      Object.keys(item).forEach(key => {
        if (key !== 'id' && key !== 'created_at' && key !== 'type') {
          if (item[key] && typeof item[key] !== 'object') {
            html += `<${tag}><b>${key}: </b> ${item[key]}</${tag}><hr/>`
          } else if (item[key] && typeof item[key] === 'object') {
            html += `<${tag} style="padding-left:16px"><b>${key}: </b> ${this.createMessageHtml(item[key], html, 'small')}</${tag}><hr/>`
          }
        }
      })
      return html
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'messages'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    } = useList()

    return {
      fetchData,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
