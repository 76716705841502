<template>
  <b-sidebar
    id="opened-modal-form"
    :visible="openedModalForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-modal-form', val)"
  >
    <template>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('admin.table.fields.clientfields') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div
        class="p-2"
        v-html="item"
      />
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
  },
  model: {
    prop: 'openedModalForm',
    event: 'update:opened-modal-form',
  },
  props: {
    openedModalForm: {
      type: Boolean,
      required: true,
    },
    item: {
      type: String,
      required: true,
    },
  },
  methods: {
    hide() {
      this.$emit('update:opened-modal-form', false)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-modal-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
